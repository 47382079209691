import React from "react"
import { graphql } from "gatsby"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const GroupOverview = ({ data }) => {
  // const groupsDE = data.groupsDE.edges
  // const groupsEN = data.groupsEN.edges
  // const groupsFR = data.groupsFR.edges
  // return (
  //   <>
  //     <div className="mt-4 px-grid">
  //       <h1 className="mb-2 text-xl">Gruppen DE:</h1>
  //       <div className="flex flex-wrap">
  //         {groupsDE.map((variant) => (
  //           <div className="w-full mb-1">
  //             <Link className="hover:text-red-500" link={variant.node.fullSlug}>
  //               {variant.node.name}
  //             </Link>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //     <div className="mt-4 px-grid">
  //       <h1 className="mb-2 text-xl">Gruppen EN:</h1>
  //       <div className="flex flex-wrap">
  //         {groupsEN.map((variant) => (
  //           <div className="w-full mb-1">
  //             <Link className="hover:text-red-500" link={variant.node.fullSlug}>
  //               {variant.node.name}
  //             </Link>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //     <div className="mt-4 px-grid">
  //       <h1 className="mb-2 text-xl">Gruppen FR:</h1>
  //       <div className="flex flex-wrap">
  //         {groupsFR.map((variant) => (
  //           <div className="w-full mb-1">
  //             <Link className="hover:text-red-500" link={variant.node.fullSlug}>
  //               {variant.node.name}
  //             </Link>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   </>
  // )
  return <div>group-overview</div>
}

// export const data = graphql`
//   {
//     groupsDE: allContentServProductGroup(filter: { locale: { eq: "de" } }) {
//       edges {
//         node {
//           name
//           locale
//           fullSlug
//         }
//       }
//     }
//     groupsEN: allContentServProductGroup(filter: { locale: { eq: "en" } }) {
//       edges {
//         node {
//           name
//           locale
//           fullSlug
//         }
//       }
//     }
//     groupsFR: allContentServProductGroup(filter: { locale: { eq: "fr" } }) {
//       edges {
//         node {
//           name
//           locale
//           fullSlug
//         }
//       }
//     }
//   }
// `

export default GroupOverview
